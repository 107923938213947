import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography, Box } from '@mui/material';
import { upperCaseEachWord } from '../stringsUtils';
import Label from '../../components/Label';
import moment from 'moment';
import NotesIcon from '@mui/icons-material/Notes';

const getLabelColor = (value) => {
  if (value === true) {
    return 'success';
  }
  if (value === false) {
    return 'error';
  }
  return 'warning';
};

const SalesListColumns = (onViewSalesNotes) => [
  {
    field: 'fullName',
    headerName: 'Name',
    flex: 1,
    minWidth: 200,
    renderCell: (params) => {
      const { row } = params;
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 0, width: '100%' }}>
          <Link
            color="inherit"
            component={RouterLink}
            to={`/dashboard/customers/${row.id}`}
            target="_blank"
            variant="subtitle2"
          >
            {`${upperCaseEachWord(row?.firstname || '')} ${upperCaseEachWord(row?.lastname || '')}`}
          </Link>
          <Typography sx={{ fontSize: '.8rem' }}>{row.email || ''}</Typography>
        </Box>
      );
    },
    valueGetter: (params) => `${params.row.firstname || ''} ${params.row.lastname || ''}`,
    filterable: false
  },
  {
    field: 'notes',
    headerName: 'Notes',
    flex: 1,
    minWidth: 200,
    renderCell: (params) => {
      const { row } = params;
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
          <NotesIcon
            sx={{
              color: 'primary.main',
              cursor: 'pointer'
            }}
            onClick={() => onViewSalesNotes(row)}
          />
          <Typography
            sx={{
              fontSize: '.8rem',
              width: '200px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {row?.latestNote?.note || 'No Notes'}
          </Typography>
        </Box>

      );
    },
  },
  {
    field: 'phonenumber',
    headerName: 'Phone Number',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => {
      const { row } = params;
      const formatPhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return 'N/A';
        const cleaned = `${phoneNumber}`.replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumber;
      };
      return (
        <Typography sx={{ fontSize: '.8rem' }}>{formatPhoneNumber(row.phonenumber)}</Typography>
      );
    },
  },
  {
    field: 'productType',
    headerName: 'Product Type',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => {
      const { row } = params;
      return row?.form?.product_type || 'N/A';
    },
  },
  {
    field: 'formSource',
    headerName: 'Form Source',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => {
      const { row } = params;
      return row?.form?.form_source || 'N/A';
    },
  },
  {
    field: 'assessmentResult',
    headerName: 'Pass/Fail',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => {
      const { row } = params;
      return (
        <Label color={getLabelColor(row?.form?.is_approved || false)}>
          {row?.form?.is_approved ? 'Pass' : 'Fail'}
        </Label>
      );
    },
  },
  {
    field: 'not_approved_reason',
    headerName: 'Not Approved Reason',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => {
      const { row } = params;
      return row?.form?.not_approved_reason || 'N/A';
    },
  },
  {
    field: 'stateusa',
    headerName: 'State',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'hasEnteredInsurance',
    headerName: 'Insurance Info',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => {
      const { row } = params;
      return (row?.insurance_company || row?.insurance_member_id) ? (
        <Label color="success">Yes</Label>
      ) : (
        <Label color="error">No</Label>
      );
    },
  },
  {
    field: 'insurance_company',
    headerName: 'Insurance Company',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => {
      const { row } = params;
      return row?.insurance_company || 'N/A';
    },
  },
  {
    field: 'insurance_member_id',
    headerName: 'Insurance Member ID',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => {
      const { row } = params;
      return row?.insurance_member_id || 'N/A';
    },
  },
  {
    field: 'insuranceProvider',
    headerName: 'Notified Of Eligibility',
    flex: 1,
    minWidth: 180,
    renderCell: (params) => {
      const { row } = params;
      const notified = row?.insurance_details?.notified_of_eligibility;
      return (
        <Label color={getLabelColor(notified)}>
          {notified ? 'Yes' : 'No'}
        </Label>
      );
    },
  },
  {
    field: 'preferredContact',
    headerName: 'Preferred Contact',
    flex: 1,
    minWidth: 180,
    renderCell: (params) => {
      const { row } = params;
      const contactMethods = row?.form?.form_data?.contact_question?.value;
      return contactMethods ? contactMethods.join(', ') : 'N/A';
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => {
      const { row } = params;
      const createdAt = row?.form?.created_at;
      return createdAt ? moment(createdAt).format('MM/DD/YYYY') : 'N/A';
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Updated Date',
    flex: 1,
    minWidth: 150,
    renderCell: (params) => {
      const { row } = params;
      const updatedAt = row?.form?.updated_at;
      return updatedAt ? moment(updatedAt).format('MM/DD/YYYY') : 'N/A';
    },
  },
];

export default SalesListColumns;
