import { createSlice } from '@reduxjs/toolkit';
import objFromArray from '../utils/objFromArray';
import { chat } from '../api/chatApi';
import { chatApi } from '../__fakeApi__/chatApi';

const initialState = {
  activeThreadId: null,
  activeContact: {

  },
  contacts: {
    chats: [],
    count: 0
  },
  chatLog: {
    chatLogs: [],
    count: 0
  },
  threads: {
    byId: {},
    allIds: []
  },
  participants: [],
  recipients: [],
  chatLogsOffset: 0,
  contactsOffset: 0
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    getContacts(state, action) {
      state.contacts.chats = action.payload.chats;
      state.contacts.count = action.payload.count;
    },
    getActiveContact(state, action) {
      state.activeContact = action.payload;
    },
    resetActiveContact(state) {
      state.activeContact = {};
    },
    getPaginatedContacts(state, action) {
      state.contacts.chats = state.contacts.chats.concat(action.payload.chats);
      state.contacts.count = action.payload.count;
    },
    resetContacts(state) {
      state.contacts.chats = [];
      state.contacts.count = 0;
    },
    getActiveChat(state, action) {
      state.activeThreadId = action.payload;
    },
    getThreads(state, action) {
      const threads = action.payload;

      state.threads.byId = objFromArray(threads);
      state.threads.allIds = Object.keys(state.threads.byId);
    },
    getChatLogs(state, action) {
      const thread = action.payload;
      if (thread) {
        state.chatLog.chatLogs = thread.chatLogs;
        state.chatLog.count = thread.count;
      }
    },
    getPaginatedChatLogs(state, action) {
      const thread = action.payload;
      if (thread) {
        state.chatLog.chatLogs = state.chatLog.chatLogs.concat(thread.chatLogs);
        state.chatLog.count = thread.count;
      }
    },
    resetChatLogs(state) {
      state.chatLog.chatLogs = [];
      state.chatLog.count = 0;
    },
    markThreadAsSeen(state, action) {
      const threadId = action.payload;
      const thread = state.threads.byId[threadId];

      if (thread) {
        thread.unreadCount = 0;
      }
    },
    resetActiveThread(state) {
      state.activeThreadId = null;
    },
    getParticipants(state, action) {
      state.participants = action.payload;
    },
    // incrementChatLogOffset(state) {
    //   state.chatLogsOffset += 1;
    // },
    incrementChatContactOffset(state) {
      state.contactsOffset += 1;
    },
    // resetChatLogOffset(state) {
    //   state.chatLogsOffset = 0;
    // },
    resetChatContactOffset(state) {
      state.contactsOffset = 0;
    }

  }
});

export const { reducer } = slice;

export const getContacts = (userSearchValue) => async (dispatch) => {
  const chatContacts = await chat.getChatList(0, 15, userSearchValue);
  dispatch(slice.actions.getContacts(chatContacts));
};
export const getActiveContact = (data) => async (dispatch) => {
  dispatch(slice.actions.getActiveContact(data));
};

export const getPaginatedContacts = (page = 0, userSearchValue) => async (dispatch) => {
  const chatContacts = await chat.getChatList(page, 15, userSearchValue);
  dispatch(slice.actions.getPaginatedContacts(chatContacts));
};

export const getThreads = () => async (dispatch) => {
  const data = await chatApi.getThreads();
  dispatch(slice.actions.getThreads(data));
};

export const getChatLogs = (chatId) => async (dispatch) => {
  const data = await chat.getChatLogs(chatId);
  dispatch(slice.actions.getChatLogs(data));
};
export const getPaginatedChatLogs = (chatId, page = 0) => async (dispatch) => {
  const chatContacts = await chat.getChatLogs(chatId, page, 8);
  dispatch(slice.actions.getPaginatedChatLogs(chatContacts));
};
export const setActiveThreadId = (chatId) => async (dispatch) => {
  dispatch(slice.actions.getActiveChat(chatId));
};

export const markThreadAsSeen = (threadId) => async (dispatch) => {
  await chatApi.markThreadAsSeen(threadId);

  dispatch(slice.actions.markThreadAsSeen(threadId));
};

export const resetActiveThread = () => (dispatch) => {
  dispatch(slice.actions.resetActiveThread());
};

export const getParticipants = (threadKey) => async (dispatch) => {
  const data = await chatApi.getParticipants(threadKey);

  dispatch(slice.actions.getParticipants(data));
};

export const incrementChatContactOffset = () => (dispatch) => {
  dispatch(slice.actions.incrementChatContactOffset());
};
export const resetChatContactOffset = () => (dispatch) => {
  dispatch(slice.actions.resetChatContactOffset());
};
export const resetChatContact = () => (dispatch) => {
  dispatch(slice.actions.resetContacts());
};
export const resetChatLogs = () => (dispatch) => {
  dispatch(slice.actions.resetChatLogs());
};
export const resetActiveContact = () => (dispatch) => {
  dispatch(slice.actions.resetActiveContact());
};
export default slice;
