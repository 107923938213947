import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Button, ListItem } from '@mui/material';

const NavItem = ({
  active,
  children,
  depth,
  icon,
  info,
  onClick,
  path,
  title
}) => {
  const style = {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '8px 16px',
    width: '100%'
  };

  if (onClick && !path) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'flex',
          py: 0
        }}
      >
        <Button
          onClick={onClick}
          startIcon={icon}
          sx={{
            ...style,
            fontSize: depth > 0 ? '0.875rem' : '1rem'
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            {title}
          </Box>
          {info}
        </Button>
      </ListItem>
    );
  }

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0
      }}
    >
      <Button
        component={path ? Link : 'button'}
        startIcon={icon}
        sx={{
          ...style,
          fontSize: depth > 0 ? '0.875rem' : '1rem'
        }}
        to={path}
      >
        <Box sx={{ flexGrow: 1 }}>
          {title}
        </Box>
        {info}
      </Button>
      {children}
    </ListItem>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  onClick: PropTypes.func,
  path: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default NavItem;
