import axios from 'axios';

export const setupAxiosInterceptors = () => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        // Clear the access token
        window.localStorage.removeItem('accessToken');
        // Redirect to login page
        window.location.href = '/dashboard';
      }
      return Promise.reject(error);
    }
  );
};
