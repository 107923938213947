import { SESSION_STATUS } from '../columns/OpsViewColumns';

export function capitalizeAfterSpace(inputString) {
  // Split the input string into words
  const words = inputString.split(' ');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => {
    // Ensure the word is not empty
    if (word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    return ''; // Handle consecutive spaces
  });

  // Join the capitalized words back together with spaces
  const resultString = capitalizedWords.join(' ');

  // Add a period at the end
  return `${resultString}.`;
}

export const getSessions = (status) => {
  if (status === 'mid') {
    return '4 Pack';
  }

  if (status === 'intro') {
    return '2 Pack';
  }

  if (status === 'experienced') {
    return '6 Pack';
  }
  if (status === 'empowered') {
    return '8 Pack';
  }
  // new style will already be like '8 Pack' etc.
  return status;
};

export function extractSessionKey(session) {
  const parts = session.split('.');
  return parts[1];
}

export const sessionLabels = (status) => {
  if (status === 'completed') {
    return 'Completed';
  }
  if (status === 'incomplete') {
    return 'Incomplete';
  }
  if (status === 'scheduled') {
    return 'Scheduled';
  }
  if (status === 'canceled') {
    return 'Canceled';
  }
  if (status === 'rescheduled') {
    return 'Rescheduled';
  }
  return '';
};

export function calculateDaysPassed(dateString) {
  // Parse the input date string into a Date object
  const inputDate = new Date(dateString);

  // Get the current date
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = currentDate - inputDate;

  // Calculate the number of days passed
  return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
}

export const statusMap = {
  [SESSION_STATUS.COMPLETED]: { label: 'Complete', backgroundColor: '#4CAF50' },
  [SESSION_STATUS.SCHEDULED]: { label: 'Scheduled', backgroundColor: '#FF9800' },
  [SESSION_STATUS.IN_COMPLETE]: { label: 'To Do', backgroundColor: '#589BFC' },
  [SESSION_STATUS.CANCELED]: { label: 'Canceled', backgroundColor: '#589BFC' },
  [SESSION_STATUS.RESCHEDULED]: { label: 'Scheduled', backgroundColor: '#FF9800' },
};
export function formatDateToMMDDYY(inputDateString) {
  const inputDate = new Date(inputDateString);

  if (Number.isNaN(inputDate)) {
    return 'Invalid Date';
  }

  const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
  const formattedDate = inputDate.toLocaleDateString('en-US', options);

  return formattedDate;
}
export function truncateString(str, length) {
  if (str.length > length) {
    return `${str.slice(0, length)}...`;
  }
  return str;
}
