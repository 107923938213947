import React, { useState, useCallback } from 'react';
import { TextField, InputAdornment, List, ListItem, ListItemText, Box, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { customerApi } from '../../api/customerApi';
import { debounce } from 'lodash';
import { styled } from '@mui/material/styles';

const SearchContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '300px',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' 
    ? 'rgba(255, 255, 255, 0.15)'
    : 'rgba(255, 255, 255, 0.1)',
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create(['background-color', 'box-shadow']),
  '& .MuiOutlinedInput-root': {
    color: theme.palette.mode === 'light' ? '#fff' : theme.palette.text.primary,
    '& input::placeholder': {
      color: theme.palette.mode === 'light' 
        ? 'rgba(255, 255, 255, 0.7)'
        : theme.palette.text.secondary,
      opacity: 1,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.mode === 'light'
        ? 'rgba(255, 255, 255, 0.3)'
        : theme.palette.divider,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.mode === 'light'
        ? 'rgba(255, 255, 255, 0.5)'
        : theme.palette.action.hover,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.mode === 'light'
        ? '#fff'
        : theme.palette.primary.main,
    },
    '& .MuiInputAdornment-root .MuiSvgIcon-root': {
      color: theme.palette.mode === 'light'
        ? 'rgba(255, 255, 255, 0.7)'
        : theme.palette.text.secondary,
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.mode === 'light'
      ? 'rgba(255, 255, 255, 0.25)'
      : 'rgba(255, 255, 255, 0.15)',
  },
}));

const ResultsList = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  left: 0,
  right: 0,
  zIndex: 1000,
  marginTop: theme.spacing(1),
  backgroundColor: theme.palette.mode === 'light'
    ? theme.palette.background.paper
    : theme.palette.background.default,
  boxShadow: theme.shadows[3],
  '& .MuiListItem-root': {
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light'
        ? theme.palette.action.hover
        : 'rgba(255, 255, 255, 0.05)',
    },
  },
  '& .MuiListItemText-primary': {
    color: theme.palette.text.primary,
  },
  '& .MuiListItemText-secondary': {
    color: theme.palette.text.secondary,
  },
}));

const PurchasesSearchBar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const fetchSearchResults = useCallback(
    debounce(async (query) => {
      if (query) {
        try {
          const params = {
            offset: 0,
            limit: 10,
            searchValue: query.toLowerCase(),
            sortBy: 'most_recent_purchase_date',
            sortDir: 'desc',
            userStatus: 'active', // Assuming we want to search active users
          };
          const data = await customerApi.getCustomers(params);
          setSearchResults(data.users.map((customer) => ({
            id: customer.id,
            email: customer.email,
            fullName: `${customer.firstname || ''} ${customer.lastname || ''}`.trim(),
            user_status: customer.user_status,
          })));
        } catch (err) {
          console.error(err);
          setSearchResults([]);
        }
      } else {
        setSearchResults([]);
      }
    }, 300),
    []
  );

  const handleQueryChange = (event) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);
    fetchSearchResults(newQuery);
  };

  const handleResultClick = (customer) => {
    window.location.href = `/dashboard/customers/${customer.id}`;
    setSearchQuery('');
    setSearchResults([]);
  };

  return (
    <SearchContainer>
      <StyledTextField
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          )
        }}
        onChange={handleQueryChange}
        placeholder="Search customers..."
        variant="outlined"
        value={searchQuery}
        size="small"
      />
      {searchResults.length > 0 && searchQuery && (
        <ResultsList>
          <List>
            {searchResults.map((result) => (
              <ListItem
                key={result.id}
                button
                onClick={() => handleResultClick(result)}
              >
                <ListItemText
                  primary={result.fullName || 'No name'}
                  secondary={result.email || 'No email'}
                />
              </ListItem>
            ))}
          </List>
        </ResultsList>
      )}
    </SearchContainer>
  );
};

export default PurchasesSearchBar;
