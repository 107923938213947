import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { renderCustomerStatus } from './OpsViewColumns';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { customerApi } from '../../api/customerApi';
import PropTypes from 'prop-types';

const editOptions = [
  {
    value: 'incomplete'
  },
  {
    value: 'completed'
  }

];

const OpsSessionEdit = (props) => {
  const { id, value, field } = props;
  console.log('val', value);
  const apiRef = useGridApiContext();
  // const ref = React.useRef();
  // React.useLayoutEffect(() => {
  //     if (hasFocus) {
  //         ref.current.focus();
  //     }
  // }, [hasFocus]);

  const handleValueChange = async (event) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: event.target.value,
    });
    const { updateTodos } = customerApi;
    await updateTodos(window.localStorage.getItem('accessToken'), id.split('|')[1], { [field.split('.')[1]]: event.target.value === 'completed' });
    apiRef.current.stopCellEditMode({ id, field, ignoreModifications: false });
  };
  return (
    <Select
      sx={{ border: 'none', outline: 'none' }}
      variant="standard"
      labelId="demo-simple-select-standard-label"
      id="demo-simple-select-standard"
      value={value}
      label="Age"
          // ref={ref}
      onChange={handleValueChange}
    >
      {editOptions.map((e, index) => (
        <MenuItem
          key={index + 1}
          value={e.value}
        >
          {renderCustomerStatus(e.value)}
        </MenuItem>
      ))}
    </Select>
  );
};
OpsSessionEdit.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  field: PropTypes.any,
};

export default OpsSessionEdit;
