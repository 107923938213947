import axios from 'axios';

class ChatApi {
  async getChatList(page = 0, limit = 10000, userSearchValue = '', adminSearchValue) {
    let queryParams = `offset=${page}&limit=${limit}`;
    if (userSearchValue) {
      queryParams += `&userSearchValue=${userSearchValue}`;
    }
    if (adminSearchValue) {
      queryParams += `&adminSearchValue=${adminSearchValue}`;
    }
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/chat/list?${queryParams}`;
    const resp = await axios.get(
      apiPath,
      {
        headers: {
          Authorization: window.localStorage.getItem('accessToken'),
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
    );
    return resp.data;
  }

  async createNewSession(customerId) {
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/chat/initialize`;
    const resp = await axios.post(
      apiPath,
      customerId,
      {
        headers: {
          Authorization: window.localStorage.getItem('accessToken'),
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
    );
    return resp.data;
  }

  async getChatCustomers(offset = 0, limit = 10000, query) {
    let queryParams = `offset=${offset}&limit=${limit}&type=All&sortBy=updatedAt&sortDir=desc`;
    if (query) {
      queryParams += `&searchValue=${query}`;
    }
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/customers/list?${queryParams}`;
    const resp = await axios.get(
      apiPath,
      {
        headers: {
          Authorization: window.localStorage.getItem('accessToken'),
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
    );
    return resp.data;
  }

  async getChatLogs(chatId, page = 0, limit = 1000) {
    const queryParams = `chatId=${chatId}&offset=${page}&limit=${limit}`;
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/chat/sms-logs?${queryParams}`;
    const resp = await axios.get(
      apiPath,
      {
        headers: {
          Authorization: window.localStorage.getItem('accessToken'),
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
    );
    return resp.data;
  }

  async sendMessage(payload) {
    const apiPath = `${process.env.REACT_APP_BACKEND_URL}/admin/chat/send-sms`;
    const resp = await axios.post(
      apiPath,
      payload,
      {
        headers: {
          Authorization: window.localStorage.getItem('accessToken'),
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
    );
    return resp.data;
  }
}

export const chat = new ChatApi();
